<template>
    <div class="main-content">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-body pt-5">
              <form-wizard ref="generateVoucher" stepSize="sm" title="" subtitle="" finishButtonText="Confirm" action="#" v-on:submit.prevent="openVoucherPreview" @on-complete="openVoucherPreview"  >
                <tab-content title="Step 1: Customer Details"
                             icon="ti-user" :selected="true" :before-change="validateFirstStep">
                  <div >
                    <div class="mb-3">
                      <label class="form-label" >Buying Options:</label> <br/>
                      <div >
                        <label>
                          <input name="buyingOption" v-model="voucher.buyingOption" type="radio" value="single" /> Single
                        </label>
                      </div>
                      <div >
                        <label>
                          <input name="buyingOption" v-model="voucher.buyingOption" type="radio" value="bulk" /> Bulk
                        </label>
                      </div>
                    </div>
                    <div v-if="voucher.buyingOption == 'single'">
                      <div class="mb-3" >
                        <label class="form-label" >Customer Type:</label> <br/>
                        <div >
                          <label>
                            <input name="existingCustomer" v-model="voucher.existingCustomer" type="radio" value="yes" /> Registered
                          </label>
                        </div>
                        <div >
                          <label>
                            <input name="existingCustomer" v-model="voucher.existingCustomer" type="radio" value="no" /> Unregistered
                          </label>
                        </div>
                      </div>

                      <div v-if="voucher.existingCustomer == 'yes'" >
                        <label class="form-label">
                          Search By Phone Number:
                        </label>
                        <div class="input-group mb-2">
                          <input v-model.trim="voucher.phoneNumber" type="text" class="form-control"  />
                          <div style="cursor: pointer;" class="input-group-append" @click="searchCustomer">
                            <span class="input-group-text" >Find</span>
                          </div>
                        </div>

                        <div v-if="customers.length > 0">
                          <table class="table table-bordered">
                            <tr>
                              <th>Select</th>
                              <th>Name</th>
                              <th>Phone number</th>
                            </tr>
                            <tr v-for="customer in customers">
                              <td> <input type="radio" :value="customer" v-model.trim="$v.voucher.customerId.$model"  name="selected_customers" /> </td>
                              <td> {{ customer.name }} </td>
                              <td> {{ customer.mobileNumber }} </td>
                            </tr>
                          </table>
                        </div>
                        <div class="text-danger" v-if="!$v.voucher.customerId.required && $v.voucher.customerId.$dirty">Please select customer to proceed</div>

                      </div>
                      <div class="mt-2" v-else>
                        <div class="form-group ">
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.name.$error }">Name:</label> <br/>
                          <div >
                            <input  v-model.trim="$v.voucher.name.$model" class="form-control" type="text" />
                          </div>
                          <div class="text-danger" v-if="!$v.voucher.name.required && $v.voucher.name.$dirty">Please enter name of recipient</div>
                        </div>
                        <div class="form-group ">
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.phoneNumber.$error }">Phone Number:</label> <br/>
                          <div >
                            <input  v-model.trim="$v.voucher.phoneNumber.$model" class="form-control" type="text" />
                          </div>
                          <div class="text-danger" v-if="!$v.voucher.phoneNumber.required && $v.voucher.phoneNumber.$dirty">Please phone number of recipient</div>
                        </div>
                        <div class="form-group ">
                          <label class="form-label" >Email Address:</label> <br/>
                          <div >
                            <input v-model.trim="voucher.emailAddress" class="form-control" type="text" />
                          </div>
                        </div>
                        <div class="form-group ">
                          <label class="form-label" >Message:</label> <br/>
                          <div >
                            <textarea class="form-control" rows="10" v-model="voucher.message"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <label class="form-label">Voucher Recipient Data</label> <br/>
                      <input class="form-control" type="file"  ref="voucherRecipientData" @change="handleVoucherRecipientFileSelected"  /> <a  href="/VoucherRecipientsCorporateTemplate.xlsx">Download Sample File</a> <br/>
                      <div class="text-danger" v-if="!$v.voucher.voucherRecipients.required && $v.voucher.voucherRecipients.$dirty">Please upload voucher recipients file</div>
                      <button v-if="voucherRecipientFile" type="button" class="btn btn-success" @click="saveVoucherRecipientsImportedFile">Upload File</button>

                      <div v-if="voucher.voucherRecipients.length > 0" style="max-height: 400px;overflow-y: scroll;">
                        <table class="table table-bordered">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Amount (KES)</th>
                          </tr>
                          <tr v-for="voucherRecipient in voucher.voucherRecipients">
                            <td>{{ voucherRecipient.name }}</td>
                            <td>{{ voucherRecipient.email }}</td>
                            <td>{{ voucherRecipient.phoneNumber }}</td>
                            <td>{{ voucherRecipient.amount }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div v-else>
                      <label class="form-label">Voucher Recipient Data</label> <br/>
                      <input class="form-control" type="file"  ref="voucherRecipientData" @change="handleVoucherRecipientFileSelected"  /> <a  href="/VoucherRecipientsCorporateTemplate.xlsx">Download Sample File</a>
                      <div class="text-danger" v-if="!$v.voucher.voucherRecipients.required && $v.voucher.voucherRecipients.$dirty">Please upload voucher recipients file</div>
                      <button v-if="voucherRecipientFile" type="button" class="btn btn-success" @click="saveVoucherRecipientsImportedFile">Upload File</button>

                      <div v-if="voucher.voucherRecipients.length > 0">
                        <table class="table table-bordered">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Amount (KES)</th>
                          </tr>
                          <tr v-for="voucherRecipient in voucher.voucherRecipients">
                            <td>{{ voucherRecipient.name }}</td>
                            <td>{{ voucherRecipient.email }}</td>
                            <td>{{ voucherRecipient.phoneNumber }}</td>
                            <td>{{ voucherRecipient.amount }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div v-else>
                      <label class="form-label">Voucher Recipient Data</label> <br/>
                      <input class="form-control" type="file"  ref="voucherRecipientData" @change="handleVoucherRecipientFileSelected"  /> <a  href="/VoucherRecipientsCorporateTemplate.xlsx">Download Sample File</a>
                    </div>
                  </div>

                </tab-content>
                <tab-content title="Step 2: Voucher Details"
                             icon="ti-user" :before-change="validateSecondStep">

                  <div class="form-group">
                    <label class="form-label" :class="{ 'text-danger': $v.voucher.voucherType.$error }">Voucher Type:</label> <br/>
                    <div >
                      <label >
                        <input name="voucherType"  v-model.trim="$v.voucher.voucherType.$model" type="radio" value="sarit" /> Sarit
                      </label>
                    </div>
                    <div >
                      <label>
                        <input name="voucherType" v-model.trim="$v.voucher.voucherType.$model" type="radio" value="outlet" /> Outlet
                      </label>
                    </div>

                    <div class="text-danger" v-if="!$v.voucher.voucherType.required && $v.voucher.voucherType.$dirty">Voucher type is required</div>

                    <div class="mt-2 mb-2" v-if="voucher.voucherType == 'outlet'">
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.voucherOutletId.$error }">Select Outlet:</label> <br/>
                      <select class="form-control"  v-model.trim="$v.voucher.voucherOutletId.$model" @change="getVouchersByOutlet">
                        <option value="0"> -- Select --</option>
                        <option :value="outlet.id" v-for="outlet in outlets">  {{ outlet.name }} </option>
                      </select>
                      <div class="text-danger" v-if="!$v.voucher.voucherOutletId.required && $v.voucher.voucherOutletId.$dirty">Outlet is required</div>
                    </div>

                    <div v-if="unutilisedVouchers.length > 0 && voucher.voucherType == 'outlet'">
                      <table class="table table-bordered">
                        <tr>
                          <th></th>
                          <th>Code</th>
                          <th>Amount</th>
                          <th>Expiry Date</th>
                        </tr>
                        <tr v-for="unutilisedVoucher in unutilisedVouchers">
                          <td><input type="checkbox" name="unutlisedVoucher" v-model.trim="$v.voucher.outletVouchers.$model" :value="unutilisedVoucher.id" /></td>
                          <td>{{ unutilisedVoucher.voucherCode }}</td>
                          <td>{{ unutilisedVoucher.amount }}</td>
                          <td>{{ unutilisedVoucher.expiryDate }}</td>
                        </tr>
                      </table>
                      <div class="text-danger" v-if="!$v.voucher.outletVouchers.required && $v.voucher.outletVouchers.$dirty">Select at least one voucher </div>
                    </div>
                  </div>

                  <div class="form-group " v-if="voucher.voucherType != 'outlet'">
                    <label class="form-label" :class="{ 'text-danger': $v.voucher.redemptionOption.$error }" >Redemption Options:</label> <br/>
                    <div >
                      <label >
                        <input name="redemptionOption" v-model.trim="$v.voucher.redemptionOption.$model" type="radio" value="any" /> Any Outlet
                      </label>
                    </div>
                    <div >
                      <label>
                        <input name="redemptionOption"  v-model.trim="$v.voucher.redemptionOption.$model" type="radio" value="specific" /> Specific
                      </label>
                    </div>

                    <div class="text-danger" v-if="!$v.voucher.redemptionOption.required && $v.voucher.redemptionOption.$dirty">Redemption option is required </div>

                    <div v-if="voucher.redemptionOption == 'specific'" >
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.outlets.$error }">
                        Outlet:
                      </label>
                      <v-select
                          label="name"
                          v-model.trim="$v.voucher.outlets.$model"
                          dir="ltr"
                          multiple
                          :options="outlets"
                      />
                      <div class="text-danger" v-if="!$v.voucher.outlets.required && $v.voucher.outlets.$dirty">Please select at least one outlet </div>
                    </div>
                  </div>

                  <div class="form-group " v-if="voucher.voucherType != 'outlet'">
                    <label class="form-label" :class="{ 'text-danger': $v.voucher.generateVoucherCode.$error }">Code Generation Option:</label> <br/>
                    <div >
                      <label >
                        <input name="generateVoucherCode" v-model.trim="$v.voucher.generateVoucherCode.$model" type="radio" value="system" /> System Generated
                      </label>
                    </div>
                    <div >
                      <label>
                        <input name="generateVoucherCode" v-model.trim="$v.voucher.generateVoucherCode.$model" type="radio" value="manual" /> Capture Manually
                      </label>
                    </div>
                    <div class="text-danger" v-if="!$v.voucher.generateVoucherCode.required && $v.voucher.generateVoucherCode.$dirty">Please select code generation option </div>

                    <div v-if="voucher.generateVoucherCode == 'manual'" >
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.voucherCode.$error }">
                        Enter voucher code:
                      </label>
                      <div >
                        <input v-model.trim="$v.voucher.voucherCode.$model" class="form-control" type="text" />
                      </div>
                      <div class="text-danger" v-if="!$v.voucher.voucherCode.required && $v.voucher.voucherCode.$dirty">Please enter voucher code </div>
                    </div>
                  </div>


                </tab-content>
                <tab-content title="Step 3: Payment"
                             icon="ti-user"  :before-change="validateThirdStep">
                  <div >
                    <div class="form-group ">
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.paymentOption.$error }" >Payment Option:</label> <br/>
                      <div >
                        <label>
                          <input name="paymentOption"  v-model.trim="$v.voucher.paymentOption.$model" type="radio" value="MPESA" /> Mpesa
                        </label>
                      </div>
                      <div v-if="voucher.existingCustomer == 'yes'">
                        <label>
                          <input name="paymentOption" v-model.trim="$v.voucher.paymentOption.$model"  type="radio" value="EPURSE" /> E-purse
                        </label>
                      </div>
                      <div >
                        <label>
                          <input name="paymentOption" v-model.trim="$v.voucher.paymentOption.$model" type="radio" value="CASH" /> Cash
                        </label>
                      </div>
                      <div >
                        <label>
                          <input name="paymentOption" v-model.trim="$v.voucher.paymentOption.$model" type="radio" value="CARD" /> Card
                        </label>
                      </div>
                      <div class="text-danger" v-if="!$v.voucher.paymentOption.required && $v.voucher.paymentOption.$dirty">Please select payment option </div>

                      <div v-if="voucher.paymentOption == 'MPESA'" >
                        <label class="form-label" :class="{ 'text-danger': $v.voucher.mpesaPhoneNumber.$error }">
                          Mpesa Phone Number:
                        </label>
                        <input  v-model.trim="$v.voucher.mpesaPhoneNumber.$model" class="form-control" type="text" />
                        <div class="text-danger" v-if="!$v.voucher.mpesaPhoneNumber.required && $v.voucher.mpesaPhoneNumber.$dirty">Please enter mpesa phone number </div>
                      </div>
                    </div>

                    <div class="form-group ">
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.paymentStatus.$error }" >Payment Status:</label> <br/>
                      <div >
                        <label>
                          <input name="paymentStatus"  v-model.trim="$v.voucher.paymentStatus.$model" type="radio" value="NOT PAID" /> Not Paid
                        </label>
                      </div>
                      <div >
                        <label>
                          <input name="paymentStatus" v-model.trim="$v.voucher.paymentStatus.$model" type="radio" value="PAID" /> Paid
                        </label>
                      </div>
                      <div class="text-danger" v-if="!$v.voucher.paymentStatus.required && $v.voucher.paymentStatus.$dirty">Please select payment status </div>

                      <div v-if="voucher.paymentStatus == 'PAID'" >
                        <label class="form-label" :class="{ 'text-danger': $v.voucher.transactionId.$error }">
                          Transaction ID:
                        </label>
                        <input  v-model.trim="$v.voucher.transactionId.$model" class="form-control" type="text" />
                        <div class="text-danger" v-if="!$v.voucher.transactionId.required && $v.voucher.transactionId.$dirty">Please enter transaction ID </div>
                      </div>
                    </div>


                    <div class="form-group">
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.amount.$error }">
                        Amount
                      </label>
                      <div >
                        <input :readonly="voucher.buyingOption == 'bulk'"  v-model.trim="$v.voucher.amount.$model" class="form-control" type="text" />
                      </div>
                      <div class="text-danger" v-if="!$v.voucher.amount.required && $v.voucher.amount.$dirty">Please enter amount </div>
                    </div>

                    <div class="form-group">
                      <label class="form-label" :class="{ 'text-danger': $v.voucher.transactedBy.$error }">
                        Transacted By
                      </label>
                      <div >
                        <input  v-model.trim="$v.voucher.transactedBy.$model" class="form-control" type="text" />
                      </div>
                      <div class="text-danger" v-if="!$v.voucher.transactedBy.required && $v.voucher.transactedBy.$dirty">Transacted by field is required </div>
                    </div>
                    <div class="mt-5" v-if="verificationCode">
                      <div class="d-flex justify-content-center">
                        <CodeInput  :fields="4" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
                      </div>
                    </div>
                  </div>
                </tab-content>
              </form-wizard>
            </div>
          </div>
        </div>
        <div class="col-3">
        </div>
      </div>
      <b-modal id="generate-voucher-preview" title="Voucher Review" @cancel="true" @ok="saveVoucher"   centered>
        <div >
          <div class="mb-3">
            <label class="form-label" >Customer Type:</label> <br/>
            <div v-if="voucher.existingCustomer == 'yes'">
              Registered
            </div>
            <div v-else>
              Unregistered
            </div>
          </div>

          <div v-if="voucher.existingCustomer == 'yes'" >

            <div v-if="customers.length > 0">
              <table class="table table-bordered">
                <tr>
                  <th>Name</th>
                  <th>Phone number</th>
                </tr>
                <tr v-for="customer in customers">
                  <td> {{ customer.name }} </td>
                  <td> {{ customer.mobileNumber }} </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mt-2" v-else>
            <div class="form-group ">
              <label class="form-label" >Name:</label> <br/>
              <div v-if="voucher.name">
                {{ voucher.name }}
              </div>
            </div>
            <div class="form-group ">
              <label class="form-label">Phone Number:</label> <br/>
              <div v-if="voucher.phoneNumber">
                {{ voucher.phoneNumber }}
              </div>
            </div>
            <div class="form-group ">
              <label class="form-label" >Email Address:</label> <br/>
              <div v-if="voucher.emailAddress">
                {{ voucher.emailAddress }}
              </div>
            </div>
            <div class="form-group ">
              <label class="form-label" >Message:</label> <br/>
              <div v-if="voucher.message">
                {{ voucher.message }}
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="row">
              <div class="col-md-12">
                <hr/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" >Voucher Type:</label> <br/>
            <div >
              {{ voucher.voucherType }}
            </div>
          </div>

          <div class="form-group " v-if="voucher.voucherType != 'outlet'">
            <label class="form-label"  >Redemption Options:</label> <br/>
            <div v-if="voucher.redemptionOption">
              Any Outlet
            </div>
            <div v-else>
              Specific
            </div>

            <div v-if="voucher.redemptionOption == 'specific'" >
              <label class="form-label" >
                Outlet:
              </label>
              <ul>
                <li v-for="redemptionOutlet in voucher.outlets">{{ redemptionOutlet.name }}</li>
              </ul>
            </div>
          </div>

          <div class="form-group" v-if="voucher.voucherType != 'outlet'">
            <label class="form-label" >Code Generation Option:</label> <br/>
            <div >
              {{ voucher.generateVoucherCode }}
            </div>
            <div v-if="voucher.generateVoucherCode == 'manual'" >
              <label class="form-label" >
                Voucher Code:
              </label>
              <div >
                {{ voucher.voucherCode }}
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="row">
              <div class="col-md-12">
                <hr/>
              </div>
            </div>
          </div>
          <div class="form-group ">
            <label class="form-label"  >Payment Status:</label> <br/>
            <div >
              {{ voucher.paymentStatus }}
            </div>
            <div v-if="voucher.paymentStatus == 'PAID'" >
              <label class="form-label" >
                Transaction ID:
              </label>
              <span v-if="voucher.transactionId">{{ voucher.transactionId }}</span>
            </div>
          </div>

          <div class="form-group ">
            <label class="form-label"  >Payment Option:</label> <br/>
            <div v-if="voucher.paymentOption">
              {{ voucher.paymentOption }}
            </div>

            <div v-if="voucher.paymentOption == 'MPESA'" >
              <label class="form-label" >
                Mpesa Phone Number:
              </label>
              <span v-if="voucher.mpesaPhoneNumber">{{ voucher.mpesaPhoneNumber }}</span>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" >
              Amount
            </label>
            <div >
              {{ voucher.amount }}
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" >
              Transacted By
            </label>
            <div >
              {{ voucher.transactedBy }}
            </div>
          </div>
        </div>
      </b-modal>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    require('vue-toastr/src/vue-toastr.scss');
    import { mapGetters, mapActions } from "vuex";
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import vSelect from "vue-select";
    import Table from "@/views/table/bs-table/Table.vue";
    import { required,requiredIf} from 'vuelidate/lib/validators'
    import CodeInput from "vue-verification-code-input";

    export default {
        name: 'gift-cards',
        data: () => ({
          fileUpload:"",
            voucherOutletId:0,
            customers:[],
            transaction_status:"all",
            lang: {
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range'
                }
            },
            card_summary:{
              totalCardsValue:"15,000",
              totalRedeemed:"5000",
              expired:"10,000"
             },
            table_loading:true,
            tickets:"",
            total_tickets:0,
            total_tickets_today:0,
            total_tickets_this_week:0,
            approval_decline_comments:"",
            from_date:"all",
            to_date:"all",
            fromDateVoucherCode:"all",
            toDateVoucherCode:"all",
            search_phone_number:"",
            rowsPerPageItems: [50, 100, 150, 200],
            selected: [],
            users:[],
            columns:[{
              key: 'created',
              label: 'Date',
              sortable: false
            },"boughtBy","recipient","amount","balance","voucherCode","paymentMode",{
              key: 'giftStatus',
              label: 'Status',
              sortable: false
            },"expiryDate","Options"],
          outletVoucherColumns:[{
            key: 'created',
            label: 'Date',
            sortable: false
          },"voucherCode","outlet","amount","expiryDate", "status","Options"],
          outletVouchers:[],
          unutilisedVouchers:[],
            isLoading: false,
            fullPage: true,
            loading:false,
            voucherStatus:"all",
            vouchers: [
            ],
             outlets:[],
            csrf: "",

            lottery:"",
            pagination: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            offset: 4,
            paginationVoucherCodes: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            offsetVoucherCodes: 4,
            voucher:{
                voucherType:"sarit",
                existingCustomer:"yes",
                buyingOption:"single",
                name:"",
                phoneNumber:"",
                emailAddress:"",
                message:"",
                redemptionOption:"any",
                outlets:[],
                amount:"",
                voucherCode:"",
                generateVoucherCode:"system",
                mpesaPhoneNumber:"",
                paymentOption:"MPESA",
                customerId:"",
                voucherOutletId:"",
                outletVouchers:[],
                paymentStatus:"NOT PAID",
                transactionId:"",
                transactedBy:"",
                duration:0,
                outletId:0,
                expiryDate:"",
                voucherRecipients:[]
            },
            currentStep:"Step 1",
            customerVerificationCode:"",
            voucherCodeVerified: false,
            verificationCode:"",
            voucherRecipientFile: null
        }),
        validations: {
          voucher:{
            voucherType: {
              required: requiredIf(function () {
                return this.currentStep == 'Step 1'
              })
            },
            name:{
              required: requiredIf(function () {
                return this.voucher.existingCustomer == 'no' && this.currentStep == 'Step 1'
                    && this.voucher.buyingOption == 'single'
              })
            },
            phoneNumber:{
              required: requiredIf(function () {
                return this.voucher.existingCustomer == 'no' && this.currentStep == 'Step 1'
                    && this.voucher.buyingOption == 'single'
              })
            },
            voucherRecipients:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 1' && this.voucher.buyingOption == 'bulk'
              })
            },
            redemptionOption:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 2'
              })
            },

            outlets:{
              required: requiredIf(function () {
                return this.voucher.redemptionOption == 'specific' && this.currentStep == 'Step 2'
              })
            },
            amount:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            voucherCode:{
              required: requiredIf(function () {
                return this.voucher.generateVoucherCode == 'manual' && this.currentStep == 'Step 2'
              })
            },
            generateVoucherCode:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 2'
              })
            },
            mpesaPhoneNumber:{
              required: requiredIf(function () {
                return this.voucher.paymentOption == 'MPESA' && this.currentStep == 'Step 3'
              })
            },
            paymentOption:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            transactedBy:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            paymentStatus:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            transactionId:{
              required: requiredIf(function () {
                return this.voucher.paymentStatus == 'PAID' && this.currentStep == 'Step 3'
              })
            },
            customerId:{
              required: requiredIf(function () {
                return this.voucher.existingCustomer == 'yes' &&  this.currentStep == 'Step 1'
                    && this.voucher.buyingOption == 'single'
              })
            },
            voucherOutletId:{
              required: requiredIf(function () {
                return this.voucher.voucherType == 'outlet'  &&  this.currentStep == 'Step 2'
              })
            },
            outletVouchers:{
              required: requiredIf(function () {
                return this.voucher.voucherType == 'outlet' &&  this.currentStep == 'Step 2'
              })
            }
          }
        },
        mounted() {
          this.getOutletsData();
          this.getOutletVoucherCodesData();
        },
        methods:{
          ...mapActions(["importVoucherRecipients","getVouchers", "getOutlets","getVoucherStats","searchCustomerByPhoneNumber","getOutletVoucherCodes","importVoucherCodes","buyAdminVoucher","sendVerificationCode"]),
          openVoucherPreview() {
            this.$bvModal.show('generate-voucher-preview');
          },
          saveVoucherRecipientsImportedFile(){
            if(this.voucherRecipientFile){
              let self = this;
              this.isLoading = true;

              let formData = new FormData();
              formData.append('excelFile', this.voucherRecipientFile);

              this.importVoucherRecipients(formData).then((response) => {
                self.isLoading = false;

                self.voucher.voucherRecipients = response.voucherRecipientsDTOS;
                self.voucher.amount = response.voucherRecipientsDTOS.reduce((sum, item) => sum + item.amount, 0)

              })
                  .catch(error => {

                    self.isLoading = false;

                    self.$bvToast.toast(error.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });

                  })

            } else {
              this.$bvToast.toast("Please upload file to proceed", {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });
            }
          },
          handleVoucherRecipientFileSelected(){
            this.voucherRecipientFile = this.$refs.voucherRecipientData.files[0];
          },
          submitVoucherCodeVerification(){

            let self = this;

              if (this.voucher.customerId) {
                self.isLoading = true;

                console.log(self.customers[0].mobileNumber);

                self.sendVerificationCode({phoneNumber: self.customers[0].mobileNumber})
                    .then(function (response) {
                      self.isLoading = false;

                      if (response.statusCode == 200) {
                        self.$bvToast.toast("Verification code sent.", {
                          title: 'Success',
                          variant: 'success',
                          autoHideDelay: 5000,
                          appendToast: false
                        });
                        self.verificationCode = response.message;
                      }

                    })
                    .catch(function (error) {

                      self.isLoading = false;

                    });
              }
          },
          verifyVcode(){
              if(this.verificationCode == this.customerVerificationCode){
                this.voucherCodeVerified = true;
              }
          },
          onComplete(v) {
            this.customerVerificationCode = v;
          },
          onChange(v) {

          },
          validateFirstStep() {
            let self = this;
            this.currentStep = "Step 1";
            return new Promise((resolve, reject) => {
              self.$v.$touch();
              if (!self.$v.$invalid) {
                resolve(true);

              } else {
                self.$bvToast.toast("Please fill in the required field", {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
                resolve(false);
              }
            })
          },
          validateSecondStep() {
            let self = this;
            this.currentStep = "Step 2";
            return new Promise((resolve, reject) => {
              self.$v.$touch();
              if (!self.$v.$invalid) {
                resolve(true);

              } else {
                self.$bvToast.toast("Please fill in the required field", {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
                resolve(false);
              }
            })
          },
          validateThirdStep() {
            let self = this;
            this.currentStep = "Step 3";
            return new Promise((resolve, reject) => {
              self.$v.$touch();
              if (!self.$v.$invalid) {
                resolve(true);
              } else {
                self.$bvToast.toast("Please fill in the required field", {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
                resolve(false);
              }
            })
          },
          handleFileUpload(){
            this.fileUpload = this.$refs.voucherCodeFile.files[0];
          },
          saveImportedFile(){
            let self = this;

            if(this.fileUpload){
              this.isLoading = true;

              let formData = new FormData();
              formData.append('excelFile', this.fileUpload);
              formData.append('outletId', this.voucherOutletId);

              this.importVoucherCodes(formData).then(() => {
                self.isLoading = false;

                self.voucherOutletId = 0;

                self.$bvToast.toast("Voucher codes imported successfully.", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getOutletVoucherCodesData();

                //self.getOutletData();

              })
                  .catch(error => {

                    console.log(error)

                    self.isLoading = false;

                    self.$bvToast.toast(error.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });

                  })

            }
          },
          saveVoucher(){
            let self = this;

            if(self.voucher.paymentOption == "EPURSE") {
              if(!self.verificationCode) {
                self.submitVoucherCodeVerification();
              } else {
                if(self.verificationCode == self.customerVerificationCode) {
                  this.completePurchase();
                } else {
                  self.$bvToast.toast("Verification code mismatch", {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });
                }
              }
            } else {
              this.completePurchase();
            }
          },
          completePurchase() {
            let self = this;
            this.isLoading = true;

            this.voucher.outlets =this.voucher.outlets.map(function (outlet){
              return outlet.id;
            });

            if(this.voucher.buyingOption == "single") {
              this.voucher.voucherRecipients = []
              this.voucher.voucherRecipients.push({
                  name: this.voucher.customerId.name,
                  email: this.voucher.customerId.email,
                  phoneNumber: this.voucher.customerId.mobileNumber,
                  amount: this.voucher.amount
              })
            }

            this.voucher.customerId =  this.voucher.customerId.id;

            console.log(this.voucher);

            this.buyAdminVoucher(this.voucher).then(() => {

              self.isLoading = false;

              self.$bvToast.toast("Voucher(s) generated successfully.", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              self.$router.push("/apps/vouchers");

            })
                .catch(error => {

                  console.log(error)

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
          },
          searchCustomer(){
            let self = this;
            this.isLoading = true;

            this.searchCustomerByPhoneNumber({phone_number: this.voucher.phoneNumber})
                .then(function (customers) {
                  self.isLoading = false;
                  self.customers = customers;
                })
                .catch(function (error) {
                })
          },
          getVouchersByOutlet() {
            let self = this;
            this.isLoading = true;

            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:"all",toDate:"all", outletId:this.voucher.voucherOutletId, status: "NOT_UTILISED"})
                .then(function (outletVouchers) {
                  self.isLoading = false;
                  self.unutilisedVouchers = outletVouchers.content;

                })
                .catch(function (error) {
                  self.isLoading = false;

                })
          },
          getOutletVoucherCodesData() {
            let self = this;
            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:this.fromDateVoucherCode,toDate:this.toDateVoucherCode, outletId:this.voucherOutletId, status: this.voucherStatus})
                .then(function (outletVouchers) {
                  self.outletVouchers = outletVouchers.content;
                  self.paginationVoucherCodes.current_page = outletVouchers.number;
                  self.paginationVoucherCodes.total = outletVouchers.totalElements;
                  self.paginationVoucherCodes.per_page = outletVouchers.numberOfElements;
                  self.paginationVoucherCodes.from = outletVouchers.pageable.offset + 1 ;
                  self.paginationVoucherCodes.to = outletVouchers.pageable.pageSize;
                  self.paginationVoucherCodes.last_page = outletVouchers.totalPages;
                })
                .catch(function (error) {

                })
          },
          getOutletsData(){
              let self = this;

              this.getOutlets()
                  .then(function (outlets) {
                    self.outlets = outlets;

                  })
                  .catch(function (error) {
                    if( typeof error.response.status != "undefined"){
                      if(error.response.status == 401){
                        location.replace("/login");
                      }

                    }
                  })
            }
        },

        components:{
          Table,
          vSelect,
          CodeInput,
          FormWizard,
          TabContent,
          Loading,
          DatePicker
        }
    }
</script>

<style >

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  background-color: transparent !important;
}
.vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
  color:#4AB5E6 !important;
}


.vue-form-wizard .wizard-tab-content {
  padding-top: 50px;
}
.stepTitle{
  margin-top: -67px;
  color:#4AB5E6;
  font-weight: 600;
}
.vue-form-wizard.sm .wizard-icon-circle{
  height: 20px;
  width: 20px;
  background-color: #4AB5E6;
  border: none;
}
.vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
  top:14px;
  left:15.5%;
  width: 69.5% !important;
  background-color: #4AB5E6 !important;
  height: 2px !important;
}
table tr th{
  padding-top: 10px;
  padding-bottom: 10px;
}
fieldset.custom-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.custom-border {
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.vue-step-wizard{
  width: 100% !important;
}
.tabStatus{
  background-color: #002563 !important;
}
.tabLabel{
  font-weight: bolder;
}

label.form-label {
  font-size: 14px;
  font-weight: 700;
}

.vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
  width: 0% !important;
}
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
  background-color: #4AB5E6 !important;
  color:#000 !important;
  border: none;
  margin-top: 20px;

}

table td{
  padding: 10px;
}
</style>


